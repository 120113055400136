var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',{attrs:{"left-arrow":false,"left-text":'退出'},on:{"click-left":_vm.back}},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"platform_wrap"},[(!_vm.isBindDy)?_c('div',{staticClass:"tip"},[_c('div',[_vm._v("请绑定抖音号")])]):_vm._e(),_c('div',{staticClass:"statistics"},[_c('van-icon',{attrs:{"name":"more-o","size":"22"},on:{"click":function($event){return _vm.gotoSt(_vm.douyin.author_id, 'douyin')}}})],1),_c('div',{staticClass:"new_title"},[_c('div',{staticClass:"avatar_wrap"},[_c('img',{attrs:{"src":_vm.douyin.avatar,"alt":""}}),_c('i',[_c('img',{attrs:{"src":require("@/assets/images/douyin-logo.png"),"alt":""}})])]),_c('div',{staticClass:"title_content"},[_c('h3',[_vm._v(_vm._s(_vm.douyin.nick_name))]),_c('div',[_vm._v(" "+_vm._s(_vm.douyin.brand || "暂无品牌")+" "),_c('b',[_vm._v(_vm._s(_vm.douyin.code || "--"))])])])]),_c('div',{staticClass:"new_detail"},[_c('div',[_c('h3',[_vm._v("场次： "+_vm._s(_vm.douyin.public_time_format))]),(_vm.douyin.room_status === 2)?_c('b',{staticStyle:{"color":"#5579e2"}},[_vm._v("直播中")]):_vm._e(),(_vm.douyin.room_status === 3)?_c('b',{staticStyle:{"color":"#faab0c"}},[_vm._v("直播结束-同步中")]):_vm._e(),(_vm.douyin.room_status === 4)?_c('b',{staticStyle:{"color":"#ee0a24"}},[_vm._v("直播结束-同步完成")]):_vm._e()]),(_vm.isBindDy)?_c('button',{on:{"click":function($event){return _vm.routerTo(
              _vm.douyin.author_id,
              1,
              _vm.douyin.nick_name,
              _vm.douyin.finish_time,
              _vm.douyin.public_time
            )}}},[_vm._v(" 进入 ")]):_vm._e()])]),_c('div',{staticClass:"platform_wrap"},[(!_vm.isBindKs)?_c('div',{staticClass:"tip"},[_c('div',[_vm._v("请绑定快手号")])]):_vm._e(),_c('div',{staticClass:"new_title"},[_c('div',{staticClass:"avatar_wrap"},[_c('img',{attrs:{"src":_vm.kuaishou.avatar,"alt":""}}),_c('i',[_c('img',{attrs:{"src":require("@/assets/images/kuaishou-logo.png"),"alt":""}})])]),_c('div',{staticClass:"title_content"},[_c('h3',[_vm._v(_vm._s(_vm.kuaishou.nick_name))]),_c('div',[_vm._v(" "+_vm._s(_vm.kuaishou.brand || "暂无品牌")+" "),_c('b',[_vm._v(_vm._s(_vm.kuaishou.code || "--"))])])])]),_c('div',{staticClass:"new_detail"},[_c('div',[_c('h3',[_vm._v("场次： "+_vm._s(_vm.kuaishou.public_time_format))]),(_vm.kuaishou.room_status === 2)?_c('b',{staticStyle:{"color":"#5579e2"}},[_vm._v("直播中")]):_vm._e(),(_vm.kuaishou.room_status === 3)?_c('b',{staticStyle:{"color":"#faab0c"}},[_vm._v("直播结束-同步中")]):_vm._e(),(_vm.kuaishou.room_status === 4)?_c('b',{staticStyle:{"color":"#ee0a24"}},[_vm._v("直播结束-同步完成")]):_vm._e()]),(_vm.isBindKs)?_c('button',{on:{"click":function($event){return _vm.routerTo(
              _vm.kuaishou.author_id,
              2,
              _vm.kuaishou.nick_name,
              _vm.kuaishou.finish_time,
              _vm.kuaishou.public_time
            )}}},[_vm._v(" 进入 ")]):_vm._e()])])])])}
var staticRenderFns = []

export { render, staticRenderFns }
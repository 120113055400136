<template>
  <Layout :left-arrow="false" @click-left="back" :left-text="'退出'">
    <div class="wrapper">
      <div class="platform_wrap">
        <div class="tip" v-if="!isBindDy">
          <div>请绑定抖音号</div>
        </div>

        <div class="statistics">
          <van-icon
            name="more-o"
            size="22"
            @click="gotoSt(douyin.author_id, 'douyin')"
          />
        </div>
        <div class="new_title">
          <div class="avatar_wrap">
            <img :src="douyin.avatar" alt="" />
            <i>
              <img src="@/assets/images/douyin-logo.png" alt="" />
            </i>
          </div>
          <div class="title_content">
            <h3>{{ douyin.nick_name }}</h3>
            <div>
              {{ douyin.brand || "暂无品牌" }} <b>{{ douyin.code || "--" }}</b>
            </div>
          </div>
        </div>

        <div class="new_detail">
          <div>
            <h3>场次： {{ douyin.public_time_format }}</h3>
            <b style="color: #5579e2" v-if="douyin.room_status === 2">直播中</b>
            <b style="color: #faab0c" v-if="douyin.room_status === 3"
              >直播结束-同步中</b
            >
            <b style="color: #ee0a24" v-if="douyin.room_status === 4"
              >直播结束-同步完成</b
            >
          </div>
          <button
            @click="
              routerTo(
                douyin.author_id,
                1,
                douyin.nick_name,
                douyin.finish_time,
                douyin.public_time
              )
            "
            v-if="isBindDy"
          >
            进入
          </button>
        </div>
      </div>

      <div class="platform_wrap">
        <div class="tip" v-if="!isBindKs">
          <div>请绑定快手号</div>
        </div>
        <!-- <div class="statistics">
          <van-icon name="more-o" size="22" @click="gotoSt(kuaishou.author_id,'kuaishou')"/>
        </div> -->
        <div class="new_title">
          <div class="avatar_wrap">
            <img :src="kuaishou.avatar" alt="" />
            <i>
              <img src="@/assets/images/kuaishou-logo.png" alt="" />
            </i>
          </div>
          <div class="title_content">
            <h3>{{ kuaishou.nick_name }}</h3>
            <div>
              {{ kuaishou.brand || "暂无品牌" }}
              <b>{{ kuaishou.code || "--" }}</b>
            </div>
          </div>
        </div>

        <div class="new_detail">
          <div>
            <h3>场次： {{ kuaishou.public_time_format }}</h3>
            <b style="color: #5579e2" v-if="kuaishou.room_status === 2"
              >直播中</b
            >
            <b style="color: #faab0c" v-if="kuaishou.room_status === 3"
              >直播结束-同步中</b
            >
            <b style="color: #ee0a24" v-if="kuaishou.room_status === 4"
              >直播结束-同步完成</b
            >
          </div>
          <button
            @click="
              routerTo(
                kuaishou.author_id,
                2,
                kuaishou.nick_name,
                kuaishou.finish_time,
                kuaishou.public_time
              )
            "
            v-if="isBindKs"
          >
            进入
          </button>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import commonApi from "@/api/common.js";
import { mapState, mapMutations } from "vuex";
import Layout from "../layout";
import moment from "moment";
export default {
  components: {
    Layout,
  },
  data() {
    return {
      douyin: {
        author_id: "",
        avatar: "",
        code: "",
        finish_time: "",
        nick_name: "",
        public_time: "",
        room_status: 0,
        status: "0",
        public_time_format: "",
      },
      kuaishou: {},
      isBindDy: true,
      isBindKs: true,
    };
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  created() {
    let user_id = this.$route.query.userId;
    commonApi.getPlatformInfo(user_id).then(({ data }) => {
      if (data.douyinlist.length === 0) {
        this.isBindDy = false;
      } else {
        this.douyin = data.douyinlist[0];
        if (this.douyin.public_time) {
          this.douyin.public_time_format = moment(
            this.douyin.public_time
          ).format("YYYY-MM-DD HH:mm");
        }
      }
      if (data.kuaishoulist.length === 0) {
        this.isBindKs = false;
      } else {
        this.kuaishou = data.kuaishoulist[0];
        if (this.kuaishou.public_time) {
          this.kuaishou.public_time_format = moment(
            this.kuaishou.public_time
          ).format("YYYY-MM-DD HH:mm");
        }
      }
    });
  },
  methods: {
    ...mapMutations(["setNickName"]),
    routerTo(author_id, type, nickname, finish_time, public_time) {
      let userId = this.$route.query.userId;
      this.$router.push({
        path: "/user-list",
        query: {
          author_id,
          type,
          userId,
          nickname,
          finish_time,
          public_time,
        },
      });
    },
    back() {
      this.$router.replace("/login");
    },
    gotoSt(authorId, platform) {
      let userId = this.$route.query.userId;
      this.$router.push({
        path: "/statistics",
        query: {
          userId,
          authorId,
          platform,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.wrapper {
  width: 100vw;
  height: 100vh;
  background: #151723;
  padding: 25px 0;
  position: relative;
  .back-button {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }
}
.platform_wrap {
  &:first-child {
    margin-bottom: 25px;
  }
  position: relative;
  margin: 0 auto;
  width: 328px;
  min-height: 150px;
  padding: 20px 0;
  background: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  > .tip {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.75);
    z-index: 1;
    > div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      color: #fff;
      font-weight: 500;
      font-size: 22px;
    }
  }
  > .title {
    text-align: center;
    padding-bottom: 15px;
    > i {
      position: relative;
      top: 5px;
      display: inline-block;
      width: 24px;
      height: 24px;
      overflow: hidden;
      margin-right: 5px;
    }
    > span {
      font-weight: 500;
      color: #4a4a4a;
    }
  }
  > .detail {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 295px;
    min-height: 72px;
    margin: 0 auto;
    background: #edeff4;
    border-radius: 8px;
    padding: 12px 10px 12px 30px;
    > i {
      display: block;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 12px;
    }
    > div {
      flex: 1;
      font-weight: 500;
      > h3 {
        width: 100%;
        margin-bottom: 5px;
        font-size: 16px;
        color: #1a1a1a;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      > p {
        font-size: 12px;
        color: #9b9b9b;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        > b {
          color: #5579e2;
        }
      }
    }
  }
  > button {
    display: block;
    width: 160px;
    height: 48px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    margin: 15px auto 0;
    background: linear-gradient(90deg, #0052d9 0%, #5579e2 100%);
    border-radius: 24px;
  }
}

.new_title {
  display: flex;
  padding: 0 20px 20px;
  > .avatar_wrap {
    position: relative;
    width: 50px;
    height: 50px;
    margin-right: 20px;
    > i {
      position: absolute;
      bottom: 0;
      right: 0;
      display: block;
      width: 20px;
      height: 20px;
    }
  }
  img {
    display: block;
    width: 100%;
    height: auto;
  }
  > .title_content {
    > h3 {
      font-weight: 400;
      font-size: 16px;
      margin-bottom: 10px;
    }
    > div {
      font-size: 10px;
      color: #4a4a4a;
      b {
        color: #5579e2;
      }
    }
  }
}
.new_detail {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  > div {
    > h3 {
      font-size: 10px;
      font-weight: 400;
      margin-bottom: 5px;
    }
    > b {
      font-size: 10px;
      color: #5579e2;
    }
  }
  > button {
    display: block;
    width: 100px;
    height: 35px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    background: linear-gradient(90deg, #0052d9 0%, #5579e2 100%);
    border-radius: 5px;
  }
}

.statistics {
  position: absolute;
  top: 20px;
  right: 10px;
  color: #3a3a3a;
}
</style>